<!-- eslint-disable -->

<script setup>
import { openModal } from '@kolirt/vue-modal';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useCasinoStore } from '@/stores/casino';
import { useCustomerStore } from '@/stores/customer';
import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import VerificationEmail from '@/components/modals/VerificationEmail.vue';
import GameImage from './GameImage.vue';

const router = useRouter();
const casinoStore = useCasinoStore();
const customerStore = useCustomerStore();

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => {});
};

// Show Verification Mail Modal
const showVerificationEmailModal = () => {
	openModal(VerificationEmail).catch(() => {});
};

let show_more_loading = ref({});

// Toggle Show More
const showMoreToggle = (provider) => {
	show_more_loading.value[provider] = true;

	// Get all provider's games length
	var all_games_count = casinoStore.getLastPlayedGames.length;

	// Toggle show more
	casinoStore.showMoreToggle(provider).then(() => {
		show_more_loading.value[provider] = false;
	}).catch(() => {
		show_more_loading.value[provider] = false;
	});

	// If provider is expanded or all provider's games are 13 or less games do not scroll to provider's top
	if (casinoStore.getShowMoreProvider.includes(provider) || all_games_count <= 13) {
		return;
	}

	// Get element
	const provider_element = document.getElementById('provider_' + provider);

	// Get the element's position relative to the viewport
	const rect = provider_element.getBoundingClientRect();

	// Calculate the new scroll position with a 10-pixel offset at the top
	const scroll_top_offset = window.scrollY + rect.top - 30;

	// Scroll to the new position smoothly
	window.scrollTo({
		top: scroll_top_offset,
		behavior: "smooth"
	});
};

// Go To Game
const goToGame = (game) => {
	// Check if Customer is logged in
	if (!customerStore.isLoggedIn) {
		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	var game_id = null;
	if (process.env.VUE_APP_LICENSE_ENABLED == 'true') game_id = game.id;
	else game_id = game.game_id;

	// Go to Game's view
	router.push({ name: 'casino-game', params: { game_id: game_id } });
}

// Check if Left Container is Hidden
const isLeftContainerHidden = computed(() => {
	if (window.innerWidth >= 0 && window.innerWidth <= 991) return true;
	else return false;
});

const shouldShowMoreButton = computed(() => {
	return casinoStore.getLastPlayedGames.length > 13;
});
</script>
<template>
	<div class="container-lg casino-container-lg">
		<!-- Last Played Games Start -->
		<div v-if="Object.keys(casinoStore.getLastPlayedGames).length > 0" class="casino-feature-games">
			<div class="title">
				<h3>{{ $t('general.last_played_games') }}</h3>
			</div>
			<div class="casino-feature-outer">

				<!-- Left Container Start -->
				<div v-if="!isLeftContainerHidden" class="large-feature-game">
					<div class="feature-image">
						<GameImage :game="casinoStore.getLastPlayedGames[0]" :size="'large'"/>
					</div>
					<div class="feature-title">
						<h4>{{ casinoStore.getLastPlayedGames[0].name }}</h4>
					</div>
				</div>
				<!-- Left Container End -->

				<!-- Right Container Start -->
				<div class="feature-games-main" :class="casinoStore.getShowMoreProvider == 'show_last_played_games' ? 'show_all' : ''">
					<template v-for="game, index in casinoStore.getLastPlayedGames" :key="game">
						<div v-if="index > 0 || isLeftContainerHidden" class="featured-game-inner">
							<div class="feature-image">
								<GameImage :game="game"/>
							</div>
							<div class="feature-title">
								<h4>{{ game.name }}</h4>
							</div>
						</div>
					</template>
				</div>
				<!-- Right Container End -->
			</div>

			<!-- Show More/Less Button Start -->
			<div class="view-all-btn" :class="shouldShowMoreButton ? '' : 'view-all-btn-full-width'">
				<button v-if="shouldShowMoreButton" @click="showMoreToggle('show_last_played_games')" class="btn-small view-all-button">{{ casinoStore.getShowMoreProvider == 'show_last_played_games' ? $t('general.less') : $t('general.more') }}</button>
			</div>
			<!-- Show More/Less Button End -->
		</div>
		<!-- Last Played Games Start -->
	</div>
</template>
<style scoped>
.text-color-and-align {
	text-align: center;
	color: #fff;
}
.view-all-btn-full-width:before {
	width: 100% !important;
}
.casino-feature-games:first-child {
	padding-top: 30px !important;
}

@media (min-width: 540px) and (max-width: 670px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 670px) and (max-width: 991px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer {
		grid-template-columns: 1fr;
	}
}

.container-lg {
	padding-bottom: 30px;
}
</style>