<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import headerIcons from '@/assets/svgs/Header.json'
import { ref, onMounted } from 'vue';
import { openModal } from '@kolirt/vue-modal';
import { useRouter } from 'vue-router';
import { useLiveCasinoStore } from '@/stores/live_casino'
import { useCustomerStore } from '@/stores/customer';
import Register from '@/components/modals/Register.vue';
import Login from '@/components/modals/Login.vue';
import VerificationEmail from '@/components/modals/VerificationEmail.vue';

// Initialize Store
const router = useRouter();
const liveCasinoStore = useLiveCasinoStore();
const customerStore = useCustomerStore();

// Initialize variables
const header_icons = headerIcons;

// Splide Slider Options
const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	pagination: false,
	arrows: false,
	autoWidth: true,
	gap: 10,
});

const search_term = ref(null);

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	})
};

// Show Verification Mail Modal
const showVerificationEmailModal = () => {
	openModal(VerificationEmail);
};

const selectProvider = (provider) => {
	liveCasinoStore.selectProvider(provider);
}

const isLicenseEnabled = () => {
	return process.env.VUE_APP_LICENSE_ENABLED;
};

// Go To Game
const goToLobby = (provider_slug) => {
	// Check if Customer is logged in
	if (!customerStore.isLoggedIn) {
		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	var game_id = null;
	switch (provider_slug) {
		case 'evolution':
			game_id = '7995';
			break;
		case 'ezugi':
			game_id = '7839';
			break;
		case 'vivo':
			game_id = '6667';
			break;
		case 'absolute':
			game_id = '4573';
			break;
	}

	if (!game_id) return;

	// Go to Game's view
	router.push({ name: 'casino-game', params: { game_id: game_id } });
}

// Creating a debounce function
function debounce(func, delay) {
	let debounceTimer;
	return function() {
		const context = this;
		const args = arguments;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => func.apply(context, args), delay);
	};
}

// Set search term with debouncing
const setSearch = debounce((game_name) => {
	search_term.value = game_name;
	liveCasinoStore.setSearchTerm(game_name);
}, 300);

onMounted(() => {
	search_term.value = liveCasinoStore.searchTerm;
});

</script>
<template>
	<div class="casino-tabing-header">
		<div class="container-lg casino-container-lg">
			<div class="casino-header-outer live-casino-header">
				<div class="casino-header-left">
					<div class="gaming-companys">
						<Splide :options="options">
							<SplideSlide>
								<div @click="selectProvider(null)" class="gaming-company-inner" :class="liveCasinoStore.getSelectedProviderId == null ? 'active' : ''">
									<div class="icon-box">
										<svg xmlns="http://www.w3.org/2000/svg" width="16.091" height="18.503"
											viewBox="0 0 16.091 18.503">
											<g id="Group_489" data-name="Group 489" transform="translate(0.25 0.25)">
												<g id="dice" transform="translate(0)">
													<path id="Path_529" data-name="Path 529"
														d="M55.081,15l-7.8,4.5v9l7.8,4.5,7.8-4.5v-9Z"
														transform="translate(-47.286 -15)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<path id="Path_530" data-name="Path 530"
														d="M62.877,19.5l-7.8,4.5-7.8-4.5,7.8-4.5Z"
														transform="translate(-47.286 -15)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_11" data-name="Line 11" y2="9.001"
														transform="translate(7.795 9.001)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_12" data-name="Line 12" x2="0.6"
														transform="translate(7.495 4.501)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_13" data-name="Line 13" y2="0.6"
														transform="translate(4.995 12.802)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_14" data-name="Line 14" y2="0.6"
														transform="translate(2.795 9.401)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_15" data-name="Line 15" y2="0.6"
														transform="translate(13.346 8.551)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_16" data-name="Line 16" y2="0.6"
														transform="translate(10.046 13.652)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
													<line id="Line_17" data-name="Line 17" y2="0.6"
														transform="translate(11.696 11.102)" fill="none" stroke="#fff"
														stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
														stroke-width="0.5" />
												</g>
											</g>
										</svg>
									</div>
									<p class="all-providers">{{ $t('general.all_providers') }}</p>
								</div>
							</SplideSlide>
							<SplideSlide v-for="provider in liveCasinoStore.getProviders" :key="provider">
								<div v-if="(provider.slug == 'evolution' || provider.slug == 'ezugi' || provider.slug == 'vivo' || provider.slug == 'absolute') && isLicenseEnabled() == 'false'"
									@click="goToLobby(provider.slug)" class="gaming-company-inner"
									:class="liveCasinoStore.getSelectedProviderId == provider.id ? 'active' : ''">
									<img :src="provider.image_url" alt="">
								</div>

								<div v-else @click="selectProvider(provider)" class="gaming-company-inner" :class="liveCasinoStore.getSelectedProviderId == provider.id ? 'active' : ''">
									<img :src="provider.image_url" alt="">
								</div>
							</SplideSlide>
						</Splide>
					</div>
				</div>

				<div class="casino-header-right">
					<div class="search-inner">
						<!-- <form> -->
						<input :value="search_term" @input="setSearch($event.target.value)" type="search" :placeholder="$t('general.search_for_games')">
						<button type="button">
							<object v-html="header_icons.search"></object>
						</button>
						<!-- </form> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.casino-tabing-header .casino-header-outer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.casino-tabing-header .casino-header-outer .casino-header-left {
	width: 63% !important;
	position: relative !important;
}

.casino-tabing-header .casino-header-outer .casino-header-right {
	width: 35% !important;
	position: relative !important;
}

@media (max-width: 768px) {
	.casino-tabing-header .casino-header-outer {
		flex-direction: column !important;
		align-items: flex-start !important;
	}

	.casino-tabing-header .casino-header-outer .casino-header-left,
	.casino-tabing-header .casino-header-outer .casino-header-right {
		width: 100% !important;
	}
}
html[dir="rtl"] .casino-tabing-header .casino-header-outer .search-inner button {
	right: unset !important;
	left: 10px !important;
}

.all-providers {
	height: 30px;
	line-height: 30px;
	white-space: nowrap;
	color: #fff;
	margin-left: 5px;
	font-size: 13px;
	font-weight: 300;
}

@media (max-width: 767px) {
	.all-providers {
		height: 25px;
		line-height: 25px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.all-providers {
		height: 23px;
		line-height: 23px;
	}
}

@media (min-width: 992px) and (max-width: 1025px) {
	.all-providers {
		height: 25px;
		line-height: 25px;
	}
}

.splide__slide {
	width: auto !important;
	min-width: 150px;
}
</style>