<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { useCasinoStore } from '@/stores/casino';
import { ref, watch, onMounted } from 'vue';
import headerIcons from '@/assets/svgs/Header.json'

// Initialize Store
const casinoStore = useCasinoStore();

// Initialize variables
const header_icons = headerIcons;

// Splide Slider Options
const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	pagination: false,
	arrows: false,
	autoWidth: true,
});

const active_brand = ref(null);
const search_term = ref(null);

// Creating a debounce function
function debounce(func, delay) {
	let debounceTimer;
	return function() {
		const context = this;
		const args = arguments;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => func.apply(context, args), delay);
	};
}

// Set active brand with debouncing
const setActiveBrand = debounce((brand_name) => {
	active_brand.value = brand_name;
	casinoStore.setGameProvider(active_brand.value);
}, 300);

// Set search term with debouncing
const setSearch = debounce((game_name) => {
	search_term.value = game_name;
	casinoStore.setSearchTerm(game_name);
}, 300);

// Show Favourites
const showFavourites = () => {
	casinoStore.showFavourites();
}

watch(() => casinoStore.getFavouriteGames, (newValue, oldValue) => {
	if (newValue != oldValue && oldValue.length > 0 && newValue.length == 0 && casinoStore.showFavouritesGames == true) {
		setActiveBrand(null);
	}
});

watch(() => casinoStore.selectedProvider, (newValue) => {
	active_brand.value = newValue;
});

onMounted(() => {
	active_brand.value = casinoStore.selectedProvider;
	search_term.value = casinoStore.searchTerm;
});
</script>
<template>
	<div class="casino-header-outer">
		<!-- Brands Slider Start -->
		<div class="casino-header-left">
			<div class="tab no-border">
				<Splide :options="options" direction:direction>
					<SplideSlide v-for="brand in casinoStore.brandNames" :key="brand">
						<!-- All button -->
						<button v-if="brand.slug == 'all'" @click="setActiveBrand('all')" class="tablinks"
							:class="casinoStore.showFavouritesGames == false && casinoStore.getSelectedProviderName == 'all' ? 'active' : ''">{{
								$t('general.all') }}</button>

						<!-- Favourites button -->
						<button v-else-if="brand.slug == 'favourites' && casinoStore.getFavouriteGames.length > 0"
							@click="showFavourites()" class="tablinks"
							:class="casinoStore.showFavouritesGames == true ? 'active' : ''">{{ $t('general.favourites')
							}}</button>

						<!-- Brand button -->
						<button v-else @click="setActiveBrand(brand.slug)" class="tablinks"
							:class="casinoStore.showFavouritesGames == false && active_brand == brand.slug ? 'active' : ''">{{ brand.name }}</button>
					</SplideSlide>
				</Splide>
			</div>
		</div>
		<!-- Brands Slider End -->

		<!-- Search Start -->
		<div class="casino-header-right">
			<div class="search-inner">
				<!-- <form> -->
				<input :value="search_term" @input="setSearch($event.target.value)" type="search"
					:placeholder="$t('general.search_for_games')">
				<button type="button">
					<object v-html="header_icons.search"></object>
				</button>
				<!-- </form> -->
			</div>
		</div>
		<!-- Search End -->
	</div>
</template>
<style scoped>
.casino-tabing-header .casino-header-outer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.casino-tabing-header .casino-header-outer .casino-header-left {
	width: 63%;
	position: relative;
}

.casino-tabing-header .casino-header-outer .casino-header-right {
	width: 35%;
	position: relative;
}

@media (max-width: 768px) {
	.casino-tabing-header .casino-header-outer {
		flex-direction: column;
		align-items: flex-start;
	}

	.casino-tabing-header .casino-header-outer .casino-header-left,
	.casino-tabing-header .casino-header-outer .casino-header-right {
		width: 100%;
	}
}

html[dir="rtl"] .casino-tabing-header .casino-header-outer .search-inner button {
	right: unset !important;
	left: 10px !important;
}

.splide__slide {
    max-width: 150px; /* Example max-width */
}

.casino-tabing-header .casino-header-outer .casino-header-left .splide .splide__track .splide__list li:first-child .tablinks {
	display: unset !important;
}
</style>